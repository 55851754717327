<!--活动管理-->
<template>
	<div class="mainBox">
		<!--场景搜索-->
		<div class="selectionBar">
			<div class="flex flex-ai-c flex-jc-sb flex-wrap">
				<label>场景：</label>
				<el-button type="primary" block size="small" @click="edit()">添加活动</el-button>
			</div>
		</div>

		<!--主要内容-->
		<div class="table" style="height:calc( 100% - 175px )">
			<el-table :data="list" stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
				<el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
				<el-table-column prop="name" label="标题" show-overflow-tooltip></el-table-column>
				<el-table-column label="操作" align="center" fixed="right" width="240">
					<template slot-scope="scope">
						<el-button type="success" plain size="mini" @click="edit(scope.row)">编辑</el-button>
						<el-button type="danger" plain size="mini" @click="deletes(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<div class="paging">
				<el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count">
				</el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		activeListApi,
		deleteActiveApi
	} from '@/api/config.js';
	export default {
		data() {
			return {
				list: [], //商品列表

				title: '添加活动',
				curr: 1, //页码
				row: 10, //每页条数
				pages: 1, //总页数
				count: 0, //总条数
			}
		},
		mounted() {
			this.getList();
		},
		methods: {
			//商品列表
			getList: function() {
				activeListApi({
					curr: this.curr,
					row: this.row
				}).then(response => {
					this.list = response.list;
					this.curr = response.curr;
					this.pages = response.pages;
					this.count = response.count;
				})
			},
			//账号信息更改
			edit: function(param) {
				this.title = param ? '编辑活动' : '添加活动';
				this.$router.push({
					path:'/activeAdd',
					query:{
						id:param ? param.id : '',
						title:this.title
					}
				})
			},

			//删除账号
			deletes: function(id) {
				this.$confirm('此操作将永久删除该文件, 是否继续', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				}).then(() => {
					deleteActiveApi({
						id: id
					}).then(() => {
						this.getList();
						this.$message({
							type: 'success',
							message: '已删除!'
						});
					})
				}).catch(() => {})
			},

			//翻页
			currentChange: function(curr) {
				this.curr = curr;
				this.getList();
			},
			//改变每页条数
			sizeChange: function(row) {
				this.row = row;
				this.getList();
			},
		},
	}
</script>
